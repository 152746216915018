import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/shared/service/api.service';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  onTabChanged: Subject<any> = new Subject()

  constructor(
    private api: ApiService,
    private http: HttpClient,
  ) {
  }

  clientGridFilters = {
    name: '',
    address: '',
  }

  clientGridColumns = [
    "name",
    "address",
    "phone",
    "email",
    "status",
    "contact",
  ]


 initDetail = (clientId: string) => this.api.get('client/ui/detail/initialise', { clientId })

  initList = () => this.api.get('client/ui/list/grid', {})

  create = (formValue) => this.api.post('client/ui/create/save', formValue)
  clientSignup = (formValue) => this.api.post('open/ui/create/signup', formValue)
  checkCoupon = (formValue) => this.api.post('open/ui/validate/Coupon', formValue)
  checkCompany = (formValue) => this.api.post('open/ui/create/companyexists', formValue)
  getRefreshToken = (formValue) => this.api.post('open/ui/create/BRaccesstoken', formValue)
  getMYOBRefreshToken = (formValue) => this.api.post('open/ui/create/MYobaccesstoken', formValue)
  postMYOBCompanyId = (formValue) => this.api.post('open/ui/create/MYOBCompanyID', formValue)
  stripe = (formValue) => this.api.post('user/ui/create/stripe', formValue)
  subcription = (formValue) => this.api.post('user/ui/stripe/productsubscriptions', formValue)
  checkExists = (email) => this.api.post(`open/ui/create/exists`, email)
  update = (formValue) => this.api.post('client/ui/edit/save', formValue)
  uploadStaffDetailsFile = (formValue) => this.api.post('client/ui/upload/staff', formValue)
  uploadStaffDataFile = (formValue) => this.api.post('client/ui/upload/staffData', formValue)
  uploadMonthlyBudgetFile = (formValue) => this.api.post('client/ui/upload/staffMonthlyBudget', formValue)
  hasBillingAccesss = (formValue) => this.api.get('client/ui/billing/access', formValue)

  delete = (clientId) => this.api.post(`client/ui/edit/delete/${clientId}`, {})

  findGroups = () => this.api.get('client/ui/pbi/groups', {})
}
