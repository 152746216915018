export const environment = {
  name: 'staging',
  appVersion: 'v717demo1',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  apiUrl: 'api',
  stripePK: 'pk_test_51MHEfEGgkX51Nkz8FMFZoxVtcgK9SQk9eamz8CSIpjflvHhgEbPf5aWbWSHF7aTjsz79HGGjsL4qGVqDT5R1Nig700mmCodZYg',
  clientId: '9b555c6f-60ae-4b58-875a-f5e714c2e76f',
  clientIdMyOB: 'e92f0317-aae9-49ef-b289-ad4d60162150',
  isScheduledReport:false,
  price1: '110',
  price11: '179',
  price51: '249',
  price100: '329',
  priceBundle: '49',
  price6AU: '110',
  price1AU: '70',
  priceBundleAU: '33',
  sentryDsn: 'https://f434416d998f69c00f0f7b353146e54b@o4507185264721920.ingest.us.sentry.io/4507185748180992',
  sentryTracePropagationTargets: "staging.dashboardinsights.com",
  clientIdQuickBooks: 'AB7B4t6N1NpzEW12iM9reWFusgjCpdASpKtF9J5L29nmgcUjB6'
};
