import { Injectable } from '@angular/core';
import { UserTypes } from 'src/app/features/user/models/user.model';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { SessionService } from 'src/app/shared/service/session.service';
import { ClientTypes } from 'src/app/features/client/models/client.model';
import { AuthService } from '../../bento-auth/auth.service';
import { BehaviorSubject } from 'rxjs';
// import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  permissionObject: any;
  currentUser: any;
  currentUserOrg: any;
  userRecord: any;
  groupOptions: any;
  selectedTab: any;
  result: any;
  _errorMessage = new BehaviorSubject<string>('');
  clientId: any;
  constructor(
    private authService: AuthService,
    private session: SessionService,
    private router: Router,
  ) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log('routing detail');
    this.selectedTab = route.queryParams.tab;
    this.currentUser = this.session.getSession();
    this.session.setSession(this.currentUser);
    this.currentUserOrg = this.session.getSessionDefaultClient();
    if(this.currentUserOrg && this.currentUserOrg.currentClientTypeId && this.currentUserOrg.currentUserTypeId)
    {
      if ((this.currentUserOrg.currentClientTypeId == ClientTypes.Advisor && this.currentUserOrg.currentUserTypeId == UserTypes.ClientUser) || this.currentUserOrg.currentUserTypeId == UserTypes.User) {
        if (this.currentUserOrg.currentClientId === this.currentUser.currentClientId) {
          if ((this.selectedTab == 'Report') || (this.selectedTab == 'Platform') || (this.selectedTab == 'User') || (this.selectedTab == 'UserGroup') || (this.selectedTab == 'Staff')) {
            {
              this.router.navigate(['/error/error-7']);
              return false;
            }
          }
        }
  
      }
      else if ((this.currentUserOrg.currentClientTypeId == ClientTypes.Standard && this.currentUserOrg.currentUserTypeId == UserTypes.ClientUser) || this.currentUserOrg.currentUserTypeId == UserTypes.User) {
          if ((this.selectedTab == 'Report') || (this.selectedTab == 'Platform') || (this.selectedTab == 'User') || (this.selectedTab == 'UserGroup') || (this.selectedTab == 'Staff')) {
            {
              this.router.navigate(['/error/error-7']);
              return false;
            }
          }
      }
    }
    else{
      this.authService.logout();
      this.router.navigate(['/login'])
      return false;
    }
    console.log('user detail');
    console.log(this.currentUser)
    if (this.currentUser) {
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.authService.logout();
    this.router.navigate(['/login'])
    return false;
  }
}



