import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/shared/service/api.service';

@Injectable({
  providedIn: 'root'
})
export class AdvisoryMappingService {
  advisoryMappingListColumns = [
    "organisationName",
    "group"
  ];
  advisoryMappingListFilters = {
    organisationName: '',
  }

  constructor(
    private api: ApiService
  ) { }

  getList = (formValue) => this.api.get('advisorymapping/ui/list', formValue);
  getDetails = (formValue) => this.api.get('advisorymapping/ui/details', formValue);
  upload = (formValue) => this.api.post('advisorymapping/ui/upload', formValue);
  downloadAdvisoryMapping = (formValue) => this.api.get('advisorymapping/ui/advisoryMapping/export', formValue);
  downloadAccountMapping = (formValue) => this.api.get('advisorymapping/ui/accountMapping/export', formValue);
  downloadMultiEntityOwnershipMapping = (formValue) => this.api.get('advisorymapping/ui/multiEntityOwnership/export', formValue);
  downloadRelatedContact = (formValue) => this.api.get('advisorymapping/ui/relatedContact/export', formValue);
  downloadRelatedLoanAccount = (formValue) => this.api.get('advisorymapping/ui/relatedLoanAccount/export', formValue);
  updateAdvisoryMappingGroup = (formValue) => this.api.post('advisorymapping/ui/advisoryMapping/updateGroup', formValue);
  upsertAccountMapping = (formValue) => this.api.post('advisorymapping/ui/accountMapping/upsert', formValue);
  upsertMultiEntityOwnership = (formValue) => this.api.post('advisorymapping/ui/multiEntityOwnership/upsert', formValue);
  upsertRelatedContacts = (formValue) => this.api.post('advisorymapping/ui/advisoryMapping/upsertRelatedContacts', formValue);
  upsertRelatedLoanAccounts = (formValue) => this.api.post('advisorymapping/ui/advisoryMapping/upsertRelatedLoanAccounts', formValue);
  relatedLoanAccountList = (formValue) => this.api.get('advisorymapping/ui/advisoryMapping/relatedLoanAccountList', formValue);
  hasAdvisoryMappingAccess = (formValue) => this.api.get('advisorymapping/ui/advisoryMapping/access', formValue);
  upsertUserAccess = (formValue) => this.api.post('advisorymapping/ui/userAccess/upsert', formValue);
}
